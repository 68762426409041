
.footer-container {
    background-color: #0a0707;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    min-height: 100vx;
    margin-top: 10%;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    color: #fff;
}

.footer-subscription>p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 2px;
    font-size: 8px;
}



.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    max-height: 300px;
    font-size: small;
    margin-left: 08%;
    margin-top: 25px;
}

.footer-link-wrapper {
    display: flex;
   
}


.footer-link-items {

    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 16px;
    text-align: center;
    width: 250px;
    box-sizing: border-box;

}

.footer-link-items h2 {
    font-size: 20px;
    color: #fff;
    
}



.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;

}

.social-icon-link{
    color: #fff;
    font-size: 15px;
}

.social-media{
    max-width: 1200px;
    width: 100%;
    margin-top: 0px;
}

.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    height: 100px;
    margin-bottom: 0px;
}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    margin-bottom: 0px;
}

.social-logo{
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
}

.website-rights{
    color: #fff;
}

@media screen and (max-width: 720px)
{
    .footer-links{
        padding-top: 2rem;

    }

    .footer-input{
        width: 100%;
    }
    .btn{
        width: 100%;
    }
    .footer-link-wrapper{
        flex-direction: column;
    }

    .social-media-wrap{
        flex-direction: column;
    }
}
    


