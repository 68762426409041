.services{
    display: flex;
    max-width: 90%;
    justify-content: center;
    margin: 15px;
    margin-left: 3%;
    margin-top: 50px;
}

.heading_serv{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    color: #00FFFF;
    font-size: 44px;
    margin-top: 50px;
    margin-left: 40%;
    text-decoration: underline;
}

.circular-card {
    display: grid;
    justify-content: center;
    width: 350px;
    border-radius: 10%;
    margin: 15px;
  }

  @keyframes ease-in {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  .circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  p {
    color: rgb(255, 255, 255);
    margin-top: 1000px;
  }

  .temp{
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  .photo {
    width: 150px;
    height: 150px;
  }

  .para {
    margin-top: 20px;
  }