*{
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
  }
  
  .nav-fixed{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .navbar {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    margin-bottom: 50px;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
   
    height: 80px;
    max-width: 2400px;
    background-color: #030303;
    margin-top: 0;
    box-shadow: #fff;
    border-bottom: 1px solid red;
    
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    margin-right: 400px;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 78.5vw;
    justify-content: end;
   
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 30px;
    height: 100%;
    margin-top: 1%;
    margin-right: 30px;
    font-weight: bold;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
  
  .nav-links:hover {
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
  
  
  .logo{
    font-size: 28px;
    color:white;
    text-decoration: none;
  }
  
  span{
    color: #ea1538;
  }
  
  nav ul li{
    list-style-type: none;
    display: inline-block;
    padding: 10px 20px;
  }
  
  nav ul li a{
    color:white;
    text-decoration: none;
    font-weight: bold;
  }
  
  nav ul li a:hover{
    color: #ea1538;
    transition: .3s;
  }
  
  .navbar-buttons button{
    border:none;
    background: #5e0211;
    padding: 12px 30px;
    border-radius: 10px;
    color:white;
    font-weight: bold;
    font-size: 15px;
    transition: .4s;
    width: 150px;
  }
  
  .navbar{
    border:none;
  }
  
  button:hover{
    transform:scale(1.07);
    cursor:pointer;
  }
  
.menu-icon {
  display: none; /* Hide the menu by default */
  font-size: 2rem;
  cursor: pointer;
  margin-right: 20px; /* Add some right margin for spacing */
  position: absolute;
  top: 10px;
  left: 10px;
}

.bar {
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin: 4px auto;
  transition: 0.4s;
}

/* Active styles for mobile menu */
.active .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 5px);
}

.active .bar:nth-child(2) {
  opacity: 0;
}

.active .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -5px);
}

/* Add this media query for screens with a max-width of 960px */
@media screen and (max-width: 960px) {
  .navbar {
    margin-bottom: 0; /* Remove margin at the bottom for smaller screens */
  }

  .navbar-container {
    flex-direction: column; /* Stack items vertically for smaller screens */
    height: auto; /* Allow the container's height to adjust */
    padding: 10px; /* Add some padding for spacing */
  }

  .navbar-logo {
    margin-right: 0; /* Remove margin for logo on smaller screens */
    margin-bottom: 20px; /* Add margin at the bottom of the logo */
    text-align: center; /* Center-align the logo */
  }

  .menu-icon {
    display: block; /* Display the menu icon */
    font-size: 2rem;
    cursor: pointer;
    margin-right: 20px; /* Add some right margin for spacing */
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .nav-menu {
    display: none; /* Hide the menu by default */
    position: absolute;
    top: 70px;
    left: -100%;
    width: 100%;
    background-color: #030303;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-links {
    text-align: center; 
    padding: 1rem 0; 
  }
}
