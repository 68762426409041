/* HomeBody.css */
.homecontainer {
  margin-top: 1%;
  opacity: 0.7;
}

.carousel-inner{
  opacity: 0.8;
}

.overlay{
  .overlay-button {
    position: absolute; /* Set the button's position to absolute */
    top: 50%; /* Position the button 50% from the top of the container */
    left: 50%; /* Position the button 50% from the left of the container */
    transform: translate(-50%, -50%); /* Center the button horizontally and vertically */
    background-color: #5e0211;
    color: #fff;
    padding: 12px 30px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
}
}